import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import type { GetStaticProps } from 'next'
import type { SSRConfig } from 'next-i18next'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useIsMobileWidth } from 'components/Breakpoint'
import { Footer } from 'components/Footer'
import { Link } from 'components/Link'
import { Seo } from 'components/Seo'
import { Routes } from 'constants/routes'
import { useThemeColors } from 'hooks/useThemeColors'
import { serverSideTranslations } from 'utils/i18n'

const NotFound = () => {
  const { t } = useTranslation(['common'])
  const { colorMode } = useColorMode()
  const COLORS = useThemeColors()
  const { isMobileWidth } = useIsMobileWidth()

  return (
    <>
      <Seo title={t('NotFound.Title')} />
      <Flex
        flexDirection="column"
        minH="100vh"
        justifyContent="space-between"
        bgColor={COLORS.bgPrimary}
      >
        <Flex w="full" flexDirection="column" alignItems="center" mb={28}>
          <Image src={`/assets/zircuit_logo_header_${colorMode}.svg`} h={10} mt={24} mb="10vh" />
          <Flex w={{ base: '18rem', sm: '22.5rem' }} flexDirection="column" alignItems="center">
            <Image
              src={`/assets/404_${colorMode}.png`}
              alt={t('NotFound.ImageAlt')}
              w="17.5rem"
              h="17.5rem"
              mb={6}
            />
            <Text
              variant={isMobileWidth ? 'heading4medium' : 'heading3medium'}
              textAlign="center"
              mb={8}
            >
              {t('NotFound.Caption')}
            </Text>
            <Button as={Link} href={Routes.bridge()}>
              {t('NotFound.Cta')}
            </Button>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  )
}

export const getStaticProps: GetStaticProps<SSRConfig> = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations({ locale }, ['common'])),
  },
})

export default NotFound
